import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useInView } from "react-intersection-observer"
import tw, { styled, css } from "twin.macro"
import { keyframes } from "@emotion/react"
import SwiperCore, { Mousewheel, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import Seo from "../components/seo"

import Logo from "../images/logo.inline.svg"
import Title from "../images/index/title.inline.svg"
import TopBackground from "../images/index/top-background.png"
import MediaForbes from "../images/index/media-forbes.png"
import MediaTechCrunch from "../images/index/media-tech-crunch.png"
import MediaMinistryOfHealth from "../images/index/media-ministry-of-health.png"
import MediaNikkei from "../images/index/media-nikkei.png"
import OurProductPaircare from "../images/index/our-product-paircare.png"
import CareersMain from "../images/index/careers-main.png"
import CareersSlide1 from "../images/index/careers-slide-1.png"
import CareersSlide2 from "../images/index/careers-slide-2.png"
import CareersSlide3 from "../images/index/careers-slide-3.png"
import CareersSlide4 from "../images/index/careers-slide-4.png"
import CareersSlide5 from "../images/index/careers-slide-5.png"
import TeamMemberHinako from "../images/index/team-member-hinakosakawa.png"
import TeamMemberKatsuya from "../images/index/team-member-katsuyagoto.png"

SwiperCore.use([Mousewheel, Autoplay])

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query PartNewsQuery {
      allNewsJson(limit: 5) {
        nodes {
          image {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          date
          url
        }
      }
    }
  `)

  const [refOurProductPaircare, inViewOurProductPaircare] = useInView({
    triggerOnce: true,
    rootMargin: "-10px 0px",
  })

  return (
    <IndexLayout>
      <Seo title="" />
      <IndexTopSection>
        <GridLayout>
          <GridArea>
            <div tw="absolute pt-14">
              <Logo />
            </div>
            <div tw="flex flex-col items-center justify-center h-full">
              <div tw="pt-14">
                <Title tw="w-full" css={TitleStyle} />
              </div>
              <div
                tw="pt-12 text-lg text-center text-pink font-display-ja tracking-loose leading-loose"
                style={{
                  transform: "translateY(10px)",
                  animationDelay: "2s",
                }}
                css={[InvisibleStyle, FadeInStyle]}
              >
                人生は物語だ。
                <br />
                その物語に寄り添い、人々を豊かにするサービスを生み出す。
                <br />
                それが<span tw="font-display-en">entale</span>の使命です。
              </div>
            </div>
          </GridArea>
        </GridLayout>
      </IndexTopSection>
      <IndexSection tw="bg-pink-dark bg-opacity-50">
        <GridLayout>
          <GridArea>
            <div tw="py-16">
              <div tw="flex flex-col lg:flex-row items-center">
                <div tw="w-full lg:w-1/5">
                  <H4>FEATURED IN:</H4>
                </div>
                <div tw="pt-10 lg:pt-0 w-full flex flex-col lg:flex-row items-center justify-between space-y-4 lg:space-y-0">
                  <img
                    tw="h-16"
                    src={MediaForbes}
                    alt="Forbes JAPANは、世界的な経済誌であるForbesの日本版として、世界中のビジネスニュース、ランキング、テクノロジー、リーダーシップ、アントレプレナー、ライフスタイル、投資、金融ニュースを配信しています。"
                  />
                  <img
                    tw="h-16"
                    src={MediaTechCrunch}
                    alt="TechCrunchはスタートアップ企業の紹介やインターネットの新しいプロダクトのレビュー、そして業界の重要なニュースを扱うテクノロジーメディアです。"
                  />
                  <img
                    tw="h-16"
                    src={MediaMinistryOfHealth}
                    alt="広報誌「厚生労働」. 医療・介護・福祉・年金・労働・子育て支援 暮らしに密着した施策・情報をわかりやすく！"
                  />
                  <img
                    tw="h-16"
                    src={MediaNikkei}
                    alt="日本経済新聞の電子版。日経や日経BPの提供する経済、企業、国際、政治、マーケット、情報・通信、社会など各分野のニュース"
                  />
                </div>
              </div>
            </div>
          </GridArea>
        </GridLayout>
      </IndexSection>
      <IndexSection>
        <GridLayout>
          <GridArea>
            <div tw="py-24 lg:py-48 flex flex-col lg:flex-row">
              <div tw="w-full lg:w-1/2">
                <H4 tw="lg:sticky lg:top-36">NEWSROOM</H4>
                <H1 tw="lg:sticky lg:top-40 pt-4">
                  Company News &amp;
                  <br /> Announcements
                </H1>
              </div>
              <div tw="pt-8 lg:pt-0 w-full lg:w-1/2 flex flex-col lg:items-end">
                {data.allNewsJson.nodes.map((news, i) => (
                  <div tw="pt-12 w-full lg:w-4/5" key={`news-${i}`}>
                    <a
                      href={news.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      tw="w-full"
                    >
                      {news.image ? (
                        <Img
                          fluid={news.image.childImageSharp.fluid}
                          alt={news.title}
                        />
                      ) : (
                        <div></div>
                      )}
                      <H3 tw="pt-7">{news.title}</H3>
                      <H4 tw="pt-4 tracking-wide">{news.date}</H4>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </GridArea>
        </GridLayout>
      </IndexSection>
      <IndexSection>
        <GridLayout>
          <GridArea>
            <div tw="py-12 lg:py-48 flex flex-col-reverse lg:flex-row">
              <div tw="pt-12 lg:pt-0 w-full lg:w-3/5">
                <H4>OUR PRODUCT</H4>
                <H1 tw="pt-4">
                  LINEを利用した
                  <br />
                  生理日予測サービス
                  <br />
                  ペアケア
                </H1>
                <P tw="pt-7 lg:pt-14 w-full lg:w-3/4">
                  「ペアケア」は、80万人以上が利用するLINEを利用した生理日予測・共有サービスです。独自のAIで生理日・排卵日を予測し、パートナー共有も無料で行えます。日本において「生理」はまだ人に言いづらい文化であり、症状にも個人差があるため不安を持っても7割が何もせず自己解決しているというデータがあります。こうした状況を踏まえ、私達はすべての人に生理関する「気付き」と「安心」を提供するためにペアケアをはじめました。私たちはペアケアを通して「生理の見える化」に取り組み、自分自身やパートナー・異性の身体について知ることでより豊かな生活ができる社会の実現を目指しています。
                </P>
                <div tw="py-14">
                  <a
                    tw="block w-full lg:w-60 py-4 font-body-en font-bold tracking-loose text-white bg-navy text-center"
                    href="https://paircare.jp/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    VISIT SITE →
                  </a>
                </div>
              </div>
              <div tw="w-full lg:w-2/5 flex flex-col items-end">
                <img
                  ref={refOurProductPaircare}
                  style={{
                    transform: "translateY(100px)",
                  }}
                  css={[
                    InvisibleStyle,
                    inViewOurProductPaircare && FadeInStyle,
                  ]}
                  src={OurProductPaircare}
                  alt="LINEで生理日予測・パートナー共有ペアケア"
                />
              </div>
            </div>
          </GridArea>
        </GridLayout>
      </IndexSection>
      <IndexSection>
        <GridLayout>
          <GridArea tw="col-start-1 lg:col-start-2 col-end-13">
            <div tw="py-24 lg:py-48 flex flex-col lg:flex-row">
              <div tw="w-full lg:w-2/5 px-7 lg:px-0">
                <H4>TEAMS</H4>
                <H1 tw="pt-4">We're a small team that's growing fast!</H1>
              </div>
              <div tw="pt-20 lg:pt-0 w-full lg:w-3/5 lg:pt-10">
                <Swiper
                  slidesPerView={"auto"}
                  freeMode={true}
                  mousewheel={true}
                >
                  <SwiperSlide className="w-72 lg:w-112 ml-7 lg:ml-0 mr-11 lg:mr-15">
                    <img
                      className="w-full lg:w-2/3"
                      src={TeamMemberHinako}
                      alt="LINEで生理日予測・パートナー共有ペアケア"
                    />
                    <H2 tw="pt-6">Hinako Sakawa</H2>
                    <H4 tw="pt-6">CO-FOUNDER</H4>
                    <P tw="pt-8">
                      2011年4月より株式会社エウレカにてデザイナーのインターンとして勤務を開始し、恋活・婚活アプリ「Pairs」の立ち上げに携わる。2013年、同社に入社し2016年からはプロダクトマネージャーも兼任。2018年3月よりフリーランスとして独立し、TRIBEAU・mamagirl-link・どこでも社食など数々のサービスに従事。デザインファームBasecamp所属。企画からデザイン、プロモーションまで一気通貫型でデザインすることが得意。
                    </P>
                  </SwiperSlide>
                  <SwiperSlide className="w-72 lg:w-112 mr-7 lg:mr-15">
                    <img
                      className="w-full lg:w-2/3"
                      src={TeamMemberKatsuya}
                      alt="LINEで生理日予測・パートナー共有ペアケア"
                    />
                    <H2 tw="pt-6">Katsuya Goto</H2>
                    <H4 tw="pt-6">CO-FOUNDER</H4>
                    <P tw="pt-8">
                      2013年8月に株式会社エウレカへエンジニアインターンとしてジョイン。インターン中に「Pairs」&「Couples」のネィティブアプリ開発を担当。
                      その後、ABEJAなどで複数インターンを経験し、エウレカへ2015年4月に新卒として入社。
                      入社後はネイティブアプリ、Webフロント/バックエンド、インフラを担当。
                      2019年5月に株式会社エウレカを退職。
                      フルスタックエンジニアとしてサービスを0から1まで高速に構築するのが得意。
                    </P>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </GridArea>
        </GridLayout>
        <div tw="h-21 lg:h-72"></div>
      </IndexSection>
      <IndexSection tw="relative bg-pink-dark bg-opacity-50">
        <div tw="absolute -top-21 lg:-top-72">
          <GridLayout>
            <GridArea tw="col-start-1 col-end-13">
              <img
                className="w-90% lg:w-4/5"
                src={CareersMain}
                alt="採用写真"
              />
            </GridArea>
          </GridLayout>
        </div>
        <div tw="h-21 lg:h-72"></div>
        <GridLayout>
          <GridArea tw="col-start-1 col-end-13">
            <div tw="w-full pt-16 lg:pt-24 px-8 lg:px-0 flex flex-col lg:items-center justify-center">
              <H4>RECRUIT</H4>
              <H1 tw="pt-4">We are hiring!</H1>
              <P tw="pt-4">Entaleでは、一緒に働く仲間を募集しています！</P>
              <P tw="pt-4 lg:text-center">
                セールス、プロダクトマネージャー、マーケティング、エンジニアなど
                <br />
                さまざまな職種で随時採用を行っています。
              </P>
              <P tw="pt-4 lg:text-center">
                Entaleは、あなたらしさを大切にする環境を提供します。
                <br />
                自由でクリエイティブな職場で、一緒に成長し、新しい挑戦を楽しみましょう!
              </P>
              <div tw="pt-14 w-full lg:w-auto">
                <a
                  tw="block w-full lg:w-60 py-4 font-body-en font-bold tracking-loose text-white bg-navy text-center"
                  href="https://careers.entale.jp/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  VIEW MORE →
                </a>
              </div>
            </div>
            <div tw="pt-24 lg:pt-48">
              <Swiper
                id="careers-swiper"
                slidesPerView={"auto"}
                autoplay={{
                  delay: 0,
                }}
                loop={true}
                speed={10000}
                centeredSlides={true}
                preventInteractionOnTransition={true}
              >
                <SwiperSlide className="w-72 lg:w-112">
                  <img className="w-full" src={CareersSlide1} alt="採用写真1" />
                </SwiperSlide>
                <SwiperSlide className="w-72 lg:w-112">
                  <img className="w-full" src={CareersSlide2} alt="採用写真2" />
                </SwiperSlide>
                <SwiperSlide className="w-72 lg:w-112">
                  <img className="w-full" src={CareersSlide3} alt="採用写真3" />
                </SwiperSlide>
                <SwiperSlide className="w-72 lg:w-112">
                  <img className="w-full" src={CareersSlide4} alt="採用写真4" />
                </SwiperSlide>
                <SwiperSlide className="w-72 lg:w-112">
                  <img className="w-full" src={CareersSlide5} alt="採用写真5" />
                </SwiperSlide>
              </Swiper>
            </div>
          </GridArea>
        </GridLayout>
      </IndexSection>
      <IndexSection>
        <GridLayout>
          <GridArea>
            <div tw="py-24 lg:py-48 flex flex-col lg:flex-row">
              <div tw="w-full lg:w-1/2">
                <H4>ABOUT US</H4>
                <H1 tw="pt-4">Overview</H1>
              </div>
              <div tw="pt-20 lg:pt-0 w-full lg:w-1/2 pt-10 lg:mt-10">
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">会社名</AboutUsText>
                  <AboutUsText tw="lg:flex-1">
                    株式会社Entale（エンテール）
                  </AboutUsText>
                </div>
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">設立</AboutUsText>
                  <AboutUsText tw="lg:flex-1">2019年10月29日</AboutUsText>
                </div>
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">資本金</AboutUsText>
                  <AboutUsText tw="lg:flex-1">300万円</AboutUsText>
                </div>
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">事業内容</AboutUsText>
                  <AboutUsText tw="lg:flex-1">
                    生理予測・共有アプリ「ペアケア」の運営
                  </AboutUsText>
                </div>
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">代表者</AboutUsText>
                  <AboutUsText tw="lg:flex-1">
                    代表取締役CEO 酒匂 ひな子
                  </AboutUsText>
                </div>
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">所在地</AboutUsText>
                  <AboutUsText tw="lg:flex-1">
                    106-0032東京都港区六本木7-21-24 THE MODULE roppongi
                  </AboutUsText>
                </div>
                <div tw="flex pt-4 lg:pt-0">
                  <AboutUsText tw="lg:w-32">顧問弁護士</AboutUsText>
                  <AboutUsText tw="lg:flex-1">
                    ネクスパート法律事務所
                  </AboutUsText>
                </div>
              </div>
            </div>
          </GridArea>
        </GridLayout>
      </IndexSection>
      <IndexSection>
        <div tw="bg-navy py-10 flex flex-col lg:flex-row items-center justify-center space-y-4 lg:space-y-0 lg:space-x-24">
          <H2 tw="py-6 lg:py-12 text-pink">Get in Touch</H2>
          <Text tw="text-pink text-sm leading-loose tracking-loose text-center lg:text-left px-7 lg:px-0 lg:w-[520px]">
            お仕事のご依頼・協業やパートナーを募集しておりますのでお気軽にお問い合わせください。info@entale.jp
          </Text>
        </div>
        <div tw="bg-pink" style={{ height: "1px" }}></div>
        <div tw="bg-navy pt-12 pb-10">
          <GridLayout>
            <GridArea>
              <div tw="flex flex-col lg:flex-row items-baseline lg:items-center justify-between space-y-4 lg:space-y-0 lg:space-x-10">
                <H2 tw="py-6 lg:py-12 text-pink">entale</H2>
                <Text tw="text-pink text-xs leading-loose tracking-loose lg:pl-56">
                  <a
                    href="https://careers.entale.jp/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    採用サイト
                  </a>
                  <br />
                  <a
                    href="https://paircare.jp/document/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    個人情報の取り扱い
                  </a>
                </Text>
                <Text tw="text-pink text-xs leading-loose tracking-loose">
                  ©Entale, Inc
                  <br />
                  {/* <br /> */}
                </Text>
              </div>
            </GridArea>
          </GridLayout>
        </div>
      </IndexSection>
    </IndexLayout>
  )
}
const IndexLayout = styled.div`
  ${tw`bg-pink`}
`

const titleStyleAnimation = keyframes`
  0% {
    stroke-dashoffset: 705px;
  }
  30% {
    fill: transparent;
  }
  60%{
    stroke-dashoffset: 0;
  }
  100%{
    stroke-dashoffset: 0;
    fill: #FFF8F7;
  }
`

const TitleStyle = css`
  path {
    stroke: #fff8f7;
    stroke-width: 1px;
    fill: transparent;
    stroke-dasharray: 705px;
    stroke-dashoffset: 705px;
    animation: ${titleStyleAnimation} 1s ease-in 0s forwards;
  }

  path:nth-of-type(1),
  path:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  path:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  path:nth-of-type(4) {
    animation-delay: 0.3s;
  }
  path:nth-of-type(5) {
    animation-delay: 0.4s;
  }
  path:nth-of-type(6) {
    animation-delay: 0.5s;
  }
  path:nth-of-type(7) {
    animation-delay: 0.6s;
  }
  path:nth-of-type(8) {
    animation-delay: 0.7s;
  }
  path:nth-of-type(9) {
    animation-delay: 0.8s;
  }
  path:nth-of-type(10) {
    animation-delay: 0.9s;
  }
  path:nth-of-type(11) {
    animation-delay: 1s;
  }
  path:nth-of-type(12) {
    animation-delay: 1.1s;
  }
`

const GridLayout = styled.div`
  ${tw`grid grid-cols-12 h-full`}
`

const GridArea = styled.div`
  ${tw`col-start-2 col-end-12`}
`

const H1 = styled.div`
  ${tw`text-navy font-display-en font-bold text-4xl lg:text-7xl lg:leading-[90px] tracking-wide`}
`

const H2 = styled.div`
  ${tw`text-navy font-display-en font-bold text-3xl lg:text-6xl tracking-wide`}
`

const H3 = styled.div`
  ${tw`text-navy font-display-en font-bold text-xl lg:text-2xl tracking-wide`}
`

const H4 = styled.div`
  ${tw`text-navy font-body-en font-bold text-sm tracking-loose`}
`

const P = styled.div`
  ${tw`text-navy font-body-ja text-sm tracking-loose leading-loose`}
`

const Text = styled.div`
  ${tw`text-navy font-body-ja text-base`}
`

const AboutUsText = styled.div`
  ${tw`text-navy font-body-ja text-base lg:text-lg lg:leading-[45px] lg:tracking-[2px] flex-1 lg:flex-none`}
`

const indexTopSectionBgAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const IndexTopSection = styled.div`
  background-image: url(${TopBackground});
  background-size: 400% 400%;
  animation: ${indexTopSectionBgAnimation} 15s ease infinite;
  ${tw`h-screen`}
`

const IndexSection = styled.div`
  ${tw``}
`

const InvisibleStyle = css`
  opacity: 0;
`

const fadeIn = keyframes`
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const FadeInStyle = css`
  animation: ${fadeIn} 1s ease-out both;
`

export default IndexPage
